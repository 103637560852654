<template>
  <div class="enterprise-stores">
    <div class="stores-bg">
      <div class="step-box">
        <el-steps :active="active" finish-status="success" align-center>
          <el-step :title="$t('stores.merAuthInfo')"></el-step>
          <el-step :title="$t('stores.setStoreInfo')"></el-step>
          <el-step :title="$t('stores.success')"></el-step>
        </el-steps>
      </div>
      <div class="enterprise-stores-child">
        <div class="reason-form">
          <div
            class="d-flex flex-direction-column align-items-center color-red"
            v-if="this.$route.query.newStatus == 3"
          >
            <span class="d-flex align-items-center">
              <i class="el-icon-warning"></i>
              {{ declinedReason }}
            </span>
          </div>
          <div class="form-box" v-show="active === 0">
            <el-form
              :model="ruleForm"
              :rules="rules"
              label-position="top"
              ref="ruleForm"
              label-width="auto"
              class="demo-ruleForm"
            >
              <el-form-item :label="$t('stores.certificateType')" prop="idCardType">
                <el-select v-model="ruleForm.idCardType" :placeholder="$t('base.pleaseSelectA')">
                  <el-option
                    v-for="item in documentType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('stores.documentName')" prop="idCardName">
                <el-input v-model="ruleForm.idCardName" maxlength="125" show-word-limit></el-input>
              </el-form-item>
              <el-form-item :label="$t('stores.documentNumber')" prop="idCardNumber">
                <el-input v-model="ruleForm.idCardNumber" maxlength="64" show-word-limit></el-input>
              </el-form-item>
              <el-form-item :label="$t('stores.idPhoto')" prop="frontIdCard">
                <uploadImg v-if="ruleForm.frontIdCard" v-model="ruleForm.frontIdCard" />
                <div v-else>
                  <uploadImg v-model="ruleForm.frontIdCard" />
                </div>
              </el-form-item>
              <el-form-item :label="$t('stores.idPhotoBack')" prop="reverseIdCard">
                <uploadImg v-if="ruleForm.reverseIdCard" v-model="ruleForm.reverseIdCard" />
                <div v-else>
                  <uploadImg v-model="ruleForm.reverseIdCard" />
                </div>
              </el-form-item>
              <el-form-item :label="$t('stores.takingFrontIdCard1')" prop="takingFrontIdCard">
                <uploadImg v-if="ruleForm.takingFrontIdCard" v-model="ruleForm.takingFrontIdCard" />
                <div v-else>
                  <uploadImg v-model="ruleForm.takingFrontIdCard" />
                </div>
              </el-form-item>
              <el-form-item :label="$t('stores.takingReverseIdCard1')" prop="takingReverseIdCard">
                <uploadImg
                  v-if="ruleForm.takingReverseIdCard"
                  v-model="ruleForm.takingReverseIdCard"
                />
                <div v-else>
                  <uploadImg v-model="ruleForm.takingReverseIdCard" />
                </div>
              </el-form-item>
            </el-form>
            <Affix :offset-bottom="0">
              <div class="button-box d-flex justify-space-between">
                <el-button @click="cancelBtn()">{{$t('base.cancel')}}</el-button>
                <div>
                  <el-button @click="keepDraft()">{{$t('stores.saveDraft')}}</el-button>
                  <el-button type="primary" @click="nextSubmitForm('ruleForm')">{{$t('stores.nextStepSettingInfo')}}</el-button>
                </div>
              </div>
            </Affix>
          </div>
          <div class="form-box" v-show="active === 1">
            <el-form
              :model="storeForm"
              :rules="rulesNext"
              ref="storeForm"
              label-width="auto"
              class="demo-ruleForm"
            >
              <el-form-item :label="$t('record.openSite')">
                <div class="d-flex flex-wrap">
                  <div
                    class="site-box cursor-pointer"
                    v-for="(item, index) in unopenedSiteList"
                    :key="index"
                    :class="{ activeStyle: item.id == isActive }"
                    @click="btnActive(index, item)"
                  >{{ item.siteName }}</div>
                </div>
              </el-form-item>
              <el-form-item :label="$t('stores.merName')" prop="merName">
                <el-input v-model="storeForm.merName" maxlength="125" show-word-limit></el-input>
              </el-form-item>
              <el-form-item :label="$t('stores.merClass')" prop="categoryId">
                <el-select v-model="storeForm.categoryId" :label="$t('store.pleaseMerClass')">
                  <el-option
                    v-for="item in businessClass"
                    :key="item.categoryId"
                    :label="item.cateName"
                    :value="item.categoryId"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('stores.merLogo')" prop="logo">
                <uploadImg v-if="storeForm.logo" v-model="storeForm.logo" />
                <div v-else>
                  <uploadImg v-model="storeForm.logo" />
                </div>
              </el-form-item>
              <el-form-item :label="$t('stores.merInfo')" prop="merInfo">
                <el-input
                  type="textarea"
                  :rows="6"
                  v-model="storeForm.merInfo"
                  maxlength="500"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('stores.merAddress')" prop="merAddress">
                <el-input
                  v-model="storeForm.merAddress"
                  maxlength="255"
                  show-word-limit
                  :placeholder="$t('base.pleaseInput')"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button size="small" @click="cancelBtn()">{{$t('base.cancel')}}</el-button>
                <el-button size="small" @click="keepStoreDraft()">{{$t('stores.saveDraft')}}</el-button>
                <el-button size="small" @click="previousStep()">{{$t('base.previousStep')}}</el-button>
                <el-button size="small" type="primary" @click="submitForm('storeForm')">{{$t('base.submit')}}</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="form-box d-flex flex-direction-column align-items-center" v-if="active === 3">
            <div class="d-flex flex-direction-column align-items-center">
              <span class="pad-20-0 fs-20 font-weight-bold">{{$t('stores.successSubmit')}}</span>
              <img
                class="success-logo pad-50-0"
                width="60"
                height="60"
                src="@/assets/image/home/ic_gong.png"
                alt
              />
            </div>
            <div class="d-flex flex-direction-column align-items-center pad-20-0">
              <p class="pad-top-20" style="padding: 15px">{{$t('stores.submitTips')}}</p>
            </div>
            <div
              class="d-flex flex-direction-column justify-content-center align-items-center pad-top-50 w-100 mar-bottom-10 login-btn"
            >
              <div class="mat-20 w-100 d-flex justify-content-center">
                <el-button
                  type="primary"
                  style="width:100%;margin:50px 0;"
                  @click="viewRecords()"
                >{{$t('settled.viewAdmissionRecord')}}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import uploadImg from "@/components/upImage/uploadImg";
import Affix from '@/components/affix/index.vue'
import { applySettlement, unopenedSiteList, businessClass, storeInformation } from '@/api/base'
export default {
  components: {
    uploadImg,
    Affix,
  },
  data() {
    return {
      active: 0,
      // 证件类型
      documentType: [{
        value: 1,
        label: this.$t('stores.idCard')
      }, {
        value: 2,
        label: this.$t('stores.passport')
      }, {
        value: 3,
        label: this.$t('stores.driveLicense')
      },],
      merId: this.$route.query.merId || '', // 商户详情表的id
      id: this.$route.query.id || '',
      declinedReason: this.$route.query.declinedReason || '',// 拒绝理由
      // 认证信息
      ruleForm: {
        merType: 1,//1个人2企业
        deliveryPlace: 3,//1本地3跨境
        idCardType: null,// 证件类型
        idCardName: '',// 证件姓名
        idCardNumber: '',//证件号码
        frontIdCard: '',// 证件正面照片
        reverseIdCard: '',// 证件反面照片
        takingFrontIdCard: '',// 手持证件正面照片
        takingReverseIdCard: '',// 上传手持证件反面照片
      },
      rules: {
        idCardType: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.certificateType'), trigger: 'blur' }
        ],
        idCardName: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.documentName'), trigger: 'blur' }
        ],
        idCardNumber: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.documentNumber'), trigger: 'blur' }
        ],
        frontIdCard: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.idPhoto'), trigger: "change" }
        ],
        reverseIdCard: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.idPhotoBack'), trigger: "change" }
        ],
        takingFrontIdCard: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.takingFrontIdCard1'), trigger: "change" }
        ],
        takingReverseIdCard: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.takingReverseIdCard1'), trigger: "change" }
        ],
      },
      // 店铺信息
      isActive: 1,
      unopenedSiteList: [],
      storeForm: {
        site: this.$route.query.site || 1,
        merName: '',
        categoryId: '',
        logo: 'https://monmall-1253436512.cos.accelerate.myqcloud.com/merchant/2022-03/19a55aa303434978b48788c0aa472005.jpg',
        merInfo: '',
        merAddress: '',
      },
      // 经营分类数据
      businessClass: [],
      rulesNext: {
        merName: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.merName'), trigger: 'blur' }
        ],
        categoryId: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.merClass'), trigger: 'blur' }
        ],
        logo: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.merLogo'), trigger: 'blur' }
        ],
        merInfo: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.merInfo'), trigger: 'blur' }
        ],
        merAddress: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.merAddress'), trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.merId ? this.getStoreInformation() : ''
  },
  methods: {
    // 经营类别
    getBusinessClassArr() {
      businessClass().then(res => {
        this.businessClass = res.data
      })
    },
    // 获取站点列表
    getSiteList() {
      unopenedSiteList().then(res => {
        this.unopenedSiteList = res.data
      })
    },
    // 获取店铺信息
    getStoreInformation() {
      console.log('店铺logo', this.storeForm.logo);
      storeInformation(this.merId).then(res => {
        console.log(res.data)
        this.ruleForm = res.data
        this.storeForm = res.data
        this.storeForm.site = this.$route.query.site || 1
        this.isActive = this.storeForm.site
        // this.declinedReason = res.data.declinedReason
      })
    },
    // 下一步
    nextSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.active = 1
          this.getBusinessClassArr()
          this.getSiteList()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 保存认证信息
    keepDraft() {
      const data = Object.assign(this.ruleForm, this.storeForm)
      applySettlement(false, this.id, data).then(res => {
        console.log(res);
        this.$router.push({ path: '/signIn/settled/applicationRecord' })
      })
    },
    // 选择站点
    btnActive(index, item) {
      this.isActive = item.id
      this.storeForm.site = item.id
    },
    // 保存商户信息
    keepStoreDraft() {
      const data = Object.assign(this.ruleForm, this.storeForm)
      applySettlement(false, this.id, data).then(res => {
        console.log(res);
        this.$router.push({ path: '/signIn/settled/applicationRecord' })
      })
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = Object.assign(this.ruleForm, this.storeForm)
          applySettlement(true, this.id, data).then(res => {
            this.$message.success(res.message)
            this.active = 3
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //上一步
    previousStep() {
      this.active = 0
    },
    // 取消
    cancelBtn() {
      this.$router.push({ path: '/signIn/settled/applicationRecord' })
    },
    // 查看入驻记录
    viewRecords() {
      this.$router.push({ path: '/signIn/settled/applicationRecord' })
    }
  }
}
</script>
<style scoped>
.enterprise-stores {
  background: #f7f9ff;
  padding: 60px 0 40px;
  box-sizing: border-box;
}
.stores-bg {
  background: url("../../../assets/image/login/bg_img_apply.png") no-repeat;
  background-size: 100% 180px;
}
.enterprise-stores-child {
  padding: 0 20%;
  background-color: #f7f9ff;
}
.reason-form {
  padding-top: 50px;
  min-width: 375px;
  background: #fff;
  position: relative;
  top: -20px;
  border-radius: 4px;
}
.demo-ruleForm {
  padding: 10px 20%;
}
.button-box {
  background: #fff;
  padding: 10px;
  border-top: 1px solid #ccc;
}
.activeStyle {
  background: url("../../../assets/image/home/backActiveSite.png") no-repeat;
  background-size: 100% 100%;
  color: #fff;
  border-color: #4285f7;
}
</style>
